import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Code = makeShortcode("Code");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Text alternatives are a primary way for making information accessible because they can be rendered through any sensory modality (for example, visual, auditory, or tactile) to match the needs of the user. Providing text alternatives allows the information to be rendered in a variety of ways by a variety of user agents. For example, a person who cannot see a picture can have the text alternative read aloud using synthesized speech.`}</p>
      <p parentName="blockquote">{`Source: `}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/text-equiv-all.html"
        }}>{`https://www.w3.org/TR/UNDERSTANDING-WCAG20/text-equiv-all.html`}</a></p>
    </blockquote>
    <h2>{`Why?`}</h2>
    <p>{`Images can make the web user experience better and more pleasant, especially for users with cognitive and learning disabilities. Not all images used in websites are accessible, and that can be a major barrier. Accessible images are beneficial for everyone, especially for people using screen readers and other assistive technologies, as well as search engine optimization (SEO).`}</p>
    <p>{`When screen readers come across images, graphs, and icons without an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute, by default the screen reader will speak the name of the file, which most of the time can be unhelpful (for example: `}<inlineCode parentName="p">{`final-final-truly-final-V2.jpg`}</inlineCode>{`).`}</p>
    <p>{`You can read more about why alternative text for images is important in the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/images/#why-is-this-important"
      }}>{`Web Accessibility Tutorials Guidance on how to create websites that meet WCAG
`}</a>{`.`}</p>
    <h2>{`Guidelines and examples`}</h2>
    <h3>{`Informative and decorative images`}</h3>
    <p>{`To help you determine the best way to communicate the information of an image to a screen reader user, you can use `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/images/decision-tree/"
      }}>{`the alt text decision tree provided by the W3C`}</a>{`.`}</p>
    <p>{`Depending the purpose of the images, they can be categorized into one of two categories: `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/images/informative/"
      }}>{`informative`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/images/decorative/"
      }}>{`decorative`}</a>{` images.`}</p>
    <ul>
      <li parentName="ul">{`Informative images convey a simple concept or information that can be expressed in a short phrase or sentence. The `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text must contain the description of what is displayed visually in the image.`}</li>
      <li parentName="ul">{`Decorative images don’t add information to the content of a page. For example, an image that is included to make the website more visually attractive. You will always get an affirmative answer to the question "if the image was removed, would the user still get all the information from the page?".`}</li>
    </ul>
    <h3>{`For designers`}</h3>
    <p>{`When you create a new design and it includes images, include captions with a description for each of them. Annotate which images are informative and which ones are decorative.`}</p>
    <h3>{`For engineers`}</h3>
    <p>{`As you're developing a new component, feature, or page that contains an image, use proper `}<a parentName="p" {...{
        "href": "/guides/accessibility/semantic-html"
      }}>{`semantic HTML`}</a>{` and use the `}<inlineCode parentName="p">{`<img>`}</inlineCode>{` element with proper alternative text using the `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute.`}</p>
    <p>{`Every `}<inlineCode parentName="p">{`<img>`}</inlineCode>{` element must have an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute. It could have content for informative images or be empty for decorative images, but it must always be present.`}</p>
    <h3>{`How to write good alt text`}</h3>
    <p>{`A missing `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute is bad, but an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute with wrong or useless information is not a better solution. When writing the `}<inlineCode parentName="p">{`alt`}</inlineCode>{` text of an image, you should always have in mind some rules:`}</p>
    <ul>
      <li parentName="ul">{`Keep it short. Avoid long, boring, or irrelevant information. The `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text should be the most concise description possible of the image’s purpose.`}</li>
      <li parentName="ul">{`The alternative text should not include the words "button", "link", or "image". Screen readers already provide that information.`}</li>
      <li parentName="ul">{`In `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/images/textual/"
        }}>{`textual images`}</a>{` (images that contain words that are important to understanding the content), the words should be included in the text alternative.`}</li>
      <li parentName="ul">{`Don't fill the `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text with keywords. The `}<inlineCode parentName="li">{`alt`}</inlineCode>{` attribute is often misused as a place to store SEO keywords or copyright information.`}</li>
      <li parentName="ul">{`Combine `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text with the caption or the content. If the image is sufficiently described in the text (for example, a simple diagram illustrating what's written in the web page text) it can have a brief `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text such as "A diagram of work flow as described in the body text".`}</li>
    </ul>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Caption mdxType="Caption">Every img element must have an 'alt' attribute.</Caption>
    <Code className="language-html" mdxType="Code">{`<img src="image1.jpg" alt="A dog playing with a ball" />`}</Code>
    <br />
    <Caption mdxType="Caption">Leave the 'alt' attribute empty for decorative.</Caption>
    <Code className="language-html" mdxType="Code">{`<img src="shape.png" alt="" />`}</Code>
  </Do>
  <Dont mdxType="Dont">
    <Caption mdxType="Caption">Don't use an img element without an 'alt' attribute.</Caption>
    <Code className="language-html" mdxType="Code">{`<img src="image1.jpg"/>`}</Code>
  </Dont>
    </DoDontContainer>
    <p>{`You can read more tips in the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/images/tips/"
      }}>{`Web Accessibility Tutorials Guidance on how to create websites that meet WCAG
`}</a>{`.`}</p>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/techniques/alttext/"
        }}>{`WebAIM Alternative Text`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/accessibility/blob/main/docs/deep-dive-notes/2022-01-26-images.md"
        }}>{`Deep Dive: Making your images accessible`}</a>{` (Only accessible to GitHub staff)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2020/05/accessible-images/"
        }}>{`Accessible Images For When They Matter Most
`}</a></li>
    </ul>
    <h3>{`Related WCAG guidelines and success criteria`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/#text-alternatives"
        }}>{`Guideline 1.1 Text Alternatives`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
        }}>{`Understanding Success Criterion 1.1.1: Non-text Content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Techniques/html/H37.html"
        }}>{`H37 Technique - Using alt attributes on img elements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://html.spec.whatwg.org/multipage/images.html#alt"
        }}>{`HTML Spec - 4.8.4.4 Requirements for providing text to act as an alternative for images`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      